export const LOGIN_WITH_EMAIL_ACTION_TYPES = {
  LOGIN_WITH_EMAIL_REQUEST: "LOGIN_WITH_EMAIL_REQUEST",
  LOGIN_WITH_EMAIL_FULFILLED: "LOGIN_WITH_EMAIL_FULFILLED",
  LOGIN_WITH_EMAIL_REJECTED: "LOGIN_WITH_EMAIL_REJECTED",
};

export const FORGOT_PASSWORD_ACTION_TYPES = {
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",
  FORGOT_PASSWORD_REJECTED: "FORGOT_PASSWORD_REJECTED",
};

export const FETCH_ALL_ORDERS_ACTION_TYPES = {
  FETCH_ALL_ORDERS_REQUEST: "FETCH_ALL_ORDERS_REQUEST",
  FETCH_ALL_ORDERS_FULFILLED: "FETCH_ALL_ORDERS_FULFILLED",
  FETCH_ALL_ORDERS_REJECTED: "FETCH_ALL_ORDERS_REJECTED",
};

export const FETCH_ALL_FLEET_ACTION_TYPES = {
  FETCH_ALL_FLEET_REQUEST: "FETCH_ALL_FLEET_REQUEST",
  FETCH_ALL_FLEET_FULFILLED: "FETCH_ALL_FLEET_FULFILLED",
  FETCH_ALL_FLEET_REJECTED: "FETCH_ALL_FLEET_REJECTED",
};

export const FETCH_ALL_LOCATION_ACTION_TYPES = {
  FETCH_ALL_LOCATION_REQUEST: "FETCH_ALL_LOCATION_REQUEST",
  FETCH_ALL_LOCATION_FULFILLED: "FETCH_ALL_LOCATION_FULFILLED",
  FETCH_ALL_LOCATION_REJECTED: "FETCH_ALL_LOCATION_REJECTED",
};

export const FETCH_ALL_THRESHOLD_ACTION_TYPES = {
  FETCH_ALL_THRESHOLD_REQUEST: "FETCH_ALL_THRESHOLD_REQUEST",
  FETCH_ALL_THRESHOLD_FULFILLED: "FETCH_ALL_THRESHOLD_FULFILLED",
  FETCH_ALL_THRESHOLD_REJECTED: "FETCH_ALL_THRESHOLD_REJECTED",
};

export const FETCH_ALL_SKU_ACTION_TYPES = {
  FETCH_ALL_SKU_REQUEST: "FETCH_ALL_SKU_REQUEST",
  FETCH_ALL_SKU_FULFILLED: "FETCH_ALL_SKU_FULFILLED",
  FETCH_ALL_SKU_REJECTED: "FETCH_ALL_SKU_REJECTED",
};

export const EDIT_THRESHOLD_ACTION_TYPES = {
  EDIT_THRESHOLD_REQUEST: "EDIT_THRESHOLD_REQUEST",
  EDIT_THRESHOLD_FULFILLED: "EDIT_THRESHOLD_FULFILLED",
  EDIT_THRESHOLD_REJECTED: "EDIT_THRESHOLD_REJECTED",
};

export const SEARCH_FLEET_ACTION_TYPES = {
  SEARCH_FLEET_REQUEST: "SEARCH_FLEET_REQUEST",
  SEARCH_FLEET_FULFILLED: "SEARCH_FLEET_FULFILLED",
  SEARCH_FLEET_REJECTED: "SEARCH_FLEET_REJECTED",
};

export const SEARCH_SKU_ACTION_TYPES = {
  SEARCH_SKU_REQUEST: "SEARCH_SKU_REQUEST",
  SEARCH_SKU_FULFILLED: "SEARCH_SKU_FULFILLED",
  SEARCH_SKU_REJECTED: "SEARCH_SKU_REJECTED",
};

export const SEARCH_THRESHOLD_ACTION_TYPES = {
  SEARCH_THRESHOLD_REQUEST: "SEARCH_THRESHOLD_REQUEST",
  SEARCH_THRESHOLD_FULFILLED: "SEARCH_THRESHOLD_FULFILLED",
  SEARCH_THRESHOLD_REJECTED: "SEARCH_THRESHOLD_REJECTED",
};

export const SEARCH_LOCATION_ACTION_TYPES = {
  SEARCH_LOCATION_REQUEST: "SEARCH_LOCATION_REQUEST",
  SEARCH_LOCATION_FULFILLED: "SEARCH_LOCATION_FULFILLED",
  SEARCH_LOCATION_REJECTED: "SEARCH_LOCATION_REJECTED",
};

export const ADD_SKU_ACTION_TYPES = {
  ADD_SKU_REQUEST: "ADD_SKU_REQUEST",
  ADD_SKU_FULFILLED: "ADD_SKU_FULFILLED",
  ADD_SKU_REJECTED: "ADD_SKU_REJECTED",
};

export const DELETE_SKU_ACTION_TYPES = {
  DELETE_SKU_REQUEST: "DELETE_SKU_REQUEST",
  DELETE_SKU_FULFILLED: "DELETE_SKU_FULFILLED",
  DELETE_SKU_REJECTED: "DELETE_SKU_REJECTED",
};

export const ADD_FLEET_ACTION_TYPES = {
  ADD_FLEET_REQUEST: "ADD_FLEET_REQUEST",
  ADD_FLEET_FULFILLED: "ADD_FLEET_FULFILLED",
  ADD_FLEET_REJECTED: "ADD_FLEET_REJECTED",
};

export const EDIT_FLEET_ACTION_TYPES = {
  EDIT_FLEET_REQUEST: "EDIT_FLEET_REQUEST",
  EDIT_FLEET_FULFILLED: "EDIT_FLEET_FULFILLED",
  EDIT_FLEET_REJECTED: "EDIT_FLEET_REJECTED",
};

export const ACTIVATE_FLEET_ACTION_TYPES = {
  ACTIVATE_FLEET_REQUEST: "ACTIVATE_FLEET_REQUEST",
  ACTIVATE_FLEET_FULFILLED: "ACTIVATE_FLEET_FULFILLED",
  ACTIVATE_FLEET_REJECTED: "ACTIVATE_FLEET_REJECTED",
};

export const DELETE_FLEET_ACTION_TYPES = {
  DELETE_FLEET_REQUEST: "DELETE_FLEET_REQUEST",
  DELETE_FLEET_FULFILLED: "DELETE_FLEET_FULFILLED",
  DELETE_FLEET_REJECTED: "DELETE_FLEET_REJECTED",
};

export const ADD_LOCATION_ACTION_TYPES = {
  ADD_LOCATION_REQUEST: "ADD_LOCATION_REQUEST",
  ADD_LOCATION_FULFILLED: "ADD_LOCATION_FULFILLED",
  ADD_LOCATION_REJECTED: "ADD_LOCATION_REJECTED",
};

export const EDIT_LOCATION_ACTION_TYPES = {
  EDIT_LOCATION_REQUEST: "EDIT_LOCATION_REQUEST",
  EDIT_LOCATION_FULFILLED: "EDIT_LOCATION_FULFILLED",
  EDIT_LOCATION_REJECTED: "EDIT_LOCATION_REJECTED",
};

export const DELETE_LOCATION_ACTION_TYPES = {
  DELETE_LOCATION_REQUEST: "DELETE_LOCATION_REQUEST",
  DELETE_LOCATION_FULFILLED: "DELETE_LOCATION_FULFILLED",
  DELETE_LOCATION_REJECTED: "DELETE_LOCATION_REJECTED",
};

export const APPROVE_ORDER_ACTION_TYPES = {
  APPROVE_ORDER_REQUEST: "APPROVE_ORDER_REQUEST",
  APPROVE_ORDER_FULFILLED: "APPROVE_ORDER_FULFILLED",
  APPROVE_ORDER_REJECTED: "APPROVE_ORDER_REJECTED",
};

export const FETCH_MULTIPLE_ACTION_TYPES = {
  FETCH_MULTIPLE_REQUEST: "FETCH_MULTIPLE_REQUEST",
  FETCH_MULTIPLE_FULFILLED: "FETCH_MULTIPLE_FULFILLED",
  FETCH_MULTIPLE_REJECTED: "FETCH_MULTIPLE_REJECTED",
};

export const FETCH_REPORT_ACTION_TYPES = {
  FETCH_REPORT_REQUEST: "FETCH_REPORT_REQUEST",
  FETCH_REPORT_FULFILLED: "FETCH_REPORT_FULFILLED",
  FETCH_REPORT_REJECTED: "FETCH_REPORT_REJECTED",
};

export const FILTER_REPORT_ACTION_TYPES = {
  FILTER_REPORT_REQUEST: "FILTER_REPORT_REQUEST",
  FILTER_REPORT_FULFILLED: "FILTER_REPORT_FULFILLED",
  FILTER_REPORT_REJECTED: "FILTER_REPORT_REJECTED",
};

export const SEARCH_REPORT_ACTION_TYPES = {
  SEARCH_REPORT_REQUEST: "SEARCH_REPORT_REQUEST",
  SEARCH_REPORT_FULFILLED: "SEARCH_REPORT_FULFILLED",
  SEARCH_REPORT_REJECTED: "SEARCH_REPORT_REJECTED",
};

export const FETCH_GENERATED_ORDERS_ACTION_TYPES = {
  FETCH_GENERATED_ORDERS_REQUEST: "FETCH_GENERATED_ORDERS_REQUEST",
  FETCH_GENERATED_ORDERS_FULFILLED: "FETCH_GENERATED_ORDERS_FULFILLED",
  FETCH_GENERATED_ORDERS_REJECTED: "FETCH_GENERATED_ORDERS_REJECTED",
};

export const FETCH_DEPOT_ACTION_TYPES = {
  FETCH_DEPOT_REQUEST: "FETCH_DEPOT_REQUEST",
  FETCH_DEPOT_FULFILLED: "FETCH_DEPOT_FULFILLED",
  FETCH_DEPOT_REJECTED: "FETCH_DEPOT_REJECTED",
};

export const RESET_PASSWORD_ACTION_TYPES = {
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",
  RESET_PASSWORD_REJECTED: "RESET_PASSWORD_REJECTED",
};

export const USER_INVITE_ACTION_TYPES = {
  USER_INVITE_REQUEST: "USER_INVITE_REQUEST",
  USER_INVITE_FULFILLED: "USER_INVITE_FULFILLED",
  USER_INVITE_REJECTED: "USER_INVITE_REJECTED",
};

export const ADMIN_INVITE_ACTION_TYPES = {
  ADMIN_INVITE_REQUEST: "ADMIN_INVITE_REQUEST",
  ADMIN_INVITE_FULFILLED: "ADMIN_INVITE_FULFILLED",
  ADMIN_INVITE_REJECTED: "ADMIN_INVITE_REJECTED",
};

export const VIEW_USERS_ACTION_TYPES = {
  VIEW_USERS_REQUEST: "VIEW_USERS_REQUEST",
  VIEW_USERS_FULFILLED: "VIEW_USERS_FULFILLED",
  VIEW_USERS_REJECTED: "VIEW_USERS_REJECTED",
};

export const CREATE_ACCOUNT_ACTION_TYPES = {
  CREATE_ACCOUNT_REQUEST: "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_FULFILLED: "CREATE_ACCOUNT_FULFILLED",
  CREATE_ACCOUNT_REJECTED: "CREATE_ACCOUNT_REJECTED",
};

export const CREATE_ADMIN_ACTION_TYPES = {
  CREATE_ADMIN_REQUEST: "CREATE_ADMIN_REQUEST",
  CREATE_ADMIN_FULFILLED: "CREATE_ADMIN_FULFILLED",
  CREATE_ADMIN_REJECTED: "CREATE_ADMIN_REJECTED",
};

export const BLOCK_USER_ACTION_TYPES = {
  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_FULFILLED: "BLOCK_USER_FULFILLED",
  BLOCK_USER_REJECTED: "BLOCK_USER_REJECTED",
};

export const UNBLOCK_USER_ACTION_TYPES = {
  UNBLOCK_USER_REQUEST: "UNBLOCK_USER_REQUEST",
  UNBLOCK_USER_FULFILLED: "UNBLOCK_USER_FULFILLED",
  UNBLOCK_USER_REJECTED: "UNBLOCK_USER_REJECTED",
};

export const FETCH_TRUCK_INFORMATION_ACTION_TYPES = {
  FETCH_TRUCK_INFORMATION_REQUEST: "FETCH_TRUCK_INFORMATION_REQUEST",
  FETCH_TRUCK_INFORMATION_FULFILLED: "FETCH_TRUCK_INFORMATION_FULFILLED",
  FETCH_TRUCK_INFORMATION_REJECTED: "FETCH_TRUCK_INFORMATION_REJECTED",
};
