import React from "react";
import styled from "styled-components";
import closeSvg from "../../assets/close.svg";

export const BackDrop = ({ isOpen, handleClose, children }) => {
  if (!isOpen) return null;

  return (
    <Modal className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="closed">
          <img src={closeSvg} alt="" onClick={handleClose} />
        </div>
        {children}
      </div>
    </Modal>
  );
};
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .modal-content {
    max-width: 650px;
    width: 100%;
    background: #fff;
    padding: 20px;
    padding-top: 10px;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .closed {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }
  .closed img {
    width: 15px;
    cursor: pointer;
  }
`;
