import React, { Component } from "react";
import logo from "../../assets/logo.svg";
import settings from "../../assets/settings.svg";
import account from "../../assets/account.svg";
import { withRouter, NavLink, Link } from "react-router-dom";

class Dashboard extends Component {
  container = React.createRef();
  state = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      user: localStorage.getItem("email"),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  logout = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/login",
    });
  };

  profileClick = () => {
    this.props.history.push({
      pathname: "/my-profile",
    });
  };

  logoClick = () => {
    this.props.history.push({
      pathname: "/login",
    });
  };

  settingsClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { open, user } = this.state;
    return (
      <div id="header">
        <div className="logo-container" onClick={this.logoClick}>
          <img alt="Logo" src={logo} className="logo" />
          <p className="title">Inventory Optimization</p>
        </div>
        <div className="link-container">
          <NavLink
            exact
            to="/home"
            activeClassName="active"
            className="link"
            onClick={this.toggleClass}
          >
            Generated Order
          </NavLink>
          <NavLink
            exact
            to="/raised-order"
            activeClassName="active"
            className="link"
            onClick={this.toggleClass}
          >
            Raised Order
          </NavLink>
          <NavLink
            to="/report"
            activeClassName="active"
            className="link"
            onClick={this.toggleClass2}
          >
            Report
          </NavLink>
          <NavLink
            to="/truck-information"
            activeClassName="active"
            className="link"
            onClick={this.toggleClass}
          >
            Truck Information
          </NavLink>
        </div>
        <div className="settings-container">
          <div className="profile" onClick={this.profileClick}>
            <img alt="account" src={account} className="icon" />
            <p>{user}</p>
          </div>
          <div className="settings" ref={this.container}>
            <div className="settings-click" onClick={this.settingsClick}>
              <img alt="settings" src={settings} className="icon" />
              <p>Settings</p>
            </div>
            {open && (
              <div className="dropdown">
                <ul>
                  <Link
                    to="/users-settings"
                    style={{ textDecoration: "none", color: "#151515" }}
                  >
                    <li>Users Settings</li>
                  </Link>
                  {localStorage.getItem("priviledge") === "root" && (
                    <Link
                      to="/config-settings"
                      style={{ textDecoration: "none", color: "#151515" }}
                    >
                      <li>Configuration Settings</li>
                    </Link>
                  )}
                  <li onClick={this.logout}>Logout</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
