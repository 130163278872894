import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import { BackDrop } from "./MapBackdrop";
import truckSvg from "../../assets/truck.png";

export const ActionModal = ({ item, display, setIsModal }) => {
  const [latitude, setLatitude] = useState(Number(item?.latitude));
  const [longitude, setLongitude] = useState(Number(item?.longitude));

  const handleModalClose = () => {
    setIsModal(false);
  };

  const handleCloseButton = () => {
    setIsModal(false);
  };

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const customIcon = {
    url: truckSvg, // Replace with your image URL
    scaledSize: { width: 20, height: 20 }, // Adjust the size as needed
    origin: { x: 0, y: 0 },
    anchor: { x: 20, y: 40 }, // Anchor the marker so the point is on the location
  };

  if (!display) return null;
  return (
    <>
      <BackDrop
        isOpen={setIsModal}
        handleClose={handleModalClose}
        handleCloseButton={handleCloseButton}
      >
        <LoadScript googleMapsApiKey={process.env.MAP_API_KEY}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{ lat: latitude, lng: longitude }}
          >
            <Marker
              position={{ lat: latitude, lng: longitude }}
              icon={customIcon}
            />
          </GoogleMap>
        </LoadScript>
      </BackDrop>
    </>
  );
};

const StyledOverlay = styled.td`
  background-color: rgba(0, 0, 0, 0.6);
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  transition: transform 600ms ease-in-out;
  z-index: 999;
`;

const StyledModal = styled.td`
  background-color: hsla(var(--clr-light));
  position: absolute;
  z-index: 999;
  right: 5%;
  top: 0;
  padding: 0.7em 0 !important;
  width: 9.375em;

  .close {
    position: absolute;
    top: -10px;
    right: -5%;
    z-index: 9;
    background-color: hsla(var(--clr-blue-tint-050));
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: inline-grid;
    place-items: center;
  }
  p {
    padding: 0.5em 1em;
    width: 100%;
    text-align: left;
    &:hover {
      background: hsla(var(--clr-yellow-tint-900));
    }
  }
`;
