import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "../header/Header";
import SolidBtn from "../common/SolidBtn";
import ClearBtn from "../common/ClearBtn";
import warehouse from "../../assets/warehouse.jpg";
import Loader from "../common/Loader";
import {
  EmptyList,
  TableSkeleton,
  Pagination,
  Table,
  TableTab,
  ContentLayout,
} from "../common";
import { useLocation } from "react-router-dom";
import { fetchGeneratedOrders, fetchAllDepot } from "../../actions/dashboard";

const orderHeaders = [
  { key: 1, item: "Transaction ID" },
  { key: 2, item: "Pickup Location" },
  { key: 3, item: "Item Name" },
  { key: 4, item: "Item Number" },
  { key: 5, item: "Region" },
  { key: 6, item: "Quantity" },
  { key: 7, item: "Date" },
  { key: 8, item: "Drop off Location" },
  { key: 9, item: "Status" },
];

const GeneratedOrders = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, dataFetched } = useSelector((state) => state.dash);
  const [isError, setIsError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [willFilter, setWillFilter] = useState("");
  const [willReload, setWillReload] = useState("");
  const [total, setTotal] = useState();
  const [byRegion, setByRegion] = useState("ALL");
  const [depots, setDepots] = useState([]);
  const [byDepot, setByDepot] = useState("");
  const [bySpecific, setBySpecific] = useState("");
  const [byCustomStart, setByCustomStart] = useState("");
  const [byCustomEnd, setByCustomEnd] = useState("");
  const [pageParams, setPageParams] = useState(
    new URLSearchParams(location.search)
  );
  const [page, setPage] = useState(() => {
    let p = Number(pageParams.get("page"));
    return p > 0 ? p : 1;
  });
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);

  const reload = () => {
    setWillReload(new Date());
    setIsError(false);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { data, meta } = await dispatch(
          fetchGeneratedOrders(
            limit,
            page,
            bySpecific,
            byCustomStart,
            byCustomEnd,
            byRegion,
            byDepot
          )
        );

        console.log("///////", data.data);

        setTableData(data.data);
        setPages(data.meta.totalPages);
        setTotal(data.meta.allPagesCount);
        setPageParams({ page: page.toString() });
      } catch (error) {
        setIsError(true);
        setTableData([]);
      }
    }
    fetchData();
  }, [
    page,
    limit,
    willReload,
    byDepot,
    byRegion,
    bySpecific,
    byCustomStart,
    byCustomEnd,
    dispatch,
  ]);

  useEffect(() => {
    setPageParams(new URLSearchParams(location.search));
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchAllDepot());
      const data = response.payload.data;
      const newData = data.map((data) => data.depot);
      setDepots(newData);
    };
    fetchData();
  }, [byDepot]);

  return (
    <ContentLayout>
      <ContentWrapper>
        <div id="home">
          <Header />
          <div className="home">
            <OrderTable>
              <TableTab
                byRegion={byRegion}
                total={total}
                totalContent="Total Number of Orders"
                depots={depots}
                setByRegion={setByRegion}
                setByDepot={setByDepot}
                setWillFilter={setWillFilter}
                setBySpecific={setBySpecific}
                setByCustomStart={setByCustomStart}
                setByCustomEnd={setByCustomEnd}
              />

              {loading && <Loader />}
              {tableData?.length < 1 && !dataFetched && !loading && (
                <div className="notloaded">
                  <p>No response</p>
                  <div className="btn-container">
                    <ClearBtn text="RELOAD" click={reload} />
                  </div>
                </div>
              )}
              {tableData?.length < 1 && dataFetched && !loading && (
                <div className="empty-container">
                  <img alt="Empty" src={warehouse} className="empty" />
                  <p>No ungrouped orders for this region yet</p>
                  <div className="btn-container">
                    <ClearBtn text="CHECK AGAIN" click={reload} />
                  </div>
                </div>
              )}
              {tableData?.length > 0 && (
                <>
                  <div className="table-container">
                    <StyledTable>
                      <thead>
                        <tr>
                          {orderHeaders.map((header) => {
                            return <th key={header.key}>{header.item}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.supplier}</td>
                              <td>
                                <div className="item_name">
                                  {item.item_name}
                                </div>
                              </td>
                              <td>{item.item_no}</td>
                              <td>{item.region}</td>
                              <td>{item.quantity_needed}</td>
                              <td>{item.order_date}</td>
                              <td>{item.depot}</td>
                              <td>{item.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </StyledTable>
                  </div>
                  <Pagination
                    setLimit={setLimit}
                    limit={limit}
                    page={page}
                    pages={pages}
                    setPage={setPage}
                  />
                </>
              )}
            </OrderTable>
          </div>
        </div>
      </ContentWrapper>
    </ContentLayout>
  );
};

const Orders = (item) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isMoreAction, setIsMoreAction] = useState(false);
  let start_date = new Date(item.createdAt);
  let startDate = `${start_date.getDate()}/${
    start_date.getMonth() + 1
  }/${start_date.getFullYear()}`;

  return (
    <tr key={item.id}>
      {/* <td onClick={() => setIsViewDetails(true)}>{item.product_code}</td> */}
      <td>{item.id}</td>
      <td>{item.supplier}</td>
      <td>
        <div className="item_name">{item.item_name}</div>
      </td>
      <td>{item.item_no}</td>
      <td>{item.region}</td>
      <td>{item.quantity_needed}</td>
      <td>{item.order_date}</td>
      <td>{item.depot}</td>
      <td>{item.status}</td>
      {/* <ActionModal
        item={item}
        display={isMoreAction}
        setIsModal={setIsMoreAction}
      />
      <ViewDetailsModal
        item={item}
        display={isViewDetails}
        setIsModal={setIsViewDetails}
      /> */}
    </tr>
  );
};

const OrderTable = styled.div`
  tbody tr {
    background-color: hsla(var(--clr-light));
    border-top: 8px solid #ececec;
    position: relative;
  }

  tbody tr td:first-child {
    text-decoration: none !important;
    cursor: auto !important;
  }

  .product-img {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .three-dots {
    display: inline-grid;
    place-items: center;
    width: 100%;
  }
  .empty-container {
    display: grid;
    justify-content: center;
    padding: 50px 0;
  }
  .notloaded {
    display: grid;
    justify-content: center;
    padding: 120px 0;
    width: 100%;
  }
  .empty-container img {
    width: 500px;
  }
  .empty-container p {
    text-align: center;
  }
  .empty-container .btn-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }
  .clear-btn {
    border-radius: 3px;
    border: 1px solid #2a1770;
    height: 35px;
    color: #2a1770;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 11px;
  }
  .clear-btn:hover {
    background: #3f2e7e;
    color: #ffffff;
  }
`;

const ContentWrapper = styled.div`
  #home .home .table-container th {
    text-align: center;
    font-size: 14px;
    width: 100px;
    padding: 1.1em 3em !important;
  }

  .table-container {
    padding-bottom: 20px;
    min-height: calc(100vh - 470px);
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    height: 60vh;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    padding: 0 3em;
    font-size: var(--fs-200);
    min-width: 400px;
    overflow-y: hidden;
  }

  thead tr {
    text-align: left;
    background-color: #ffffff;
  }

  th,
  td {
    padding: 1.1em 3em;
    font-size: 14px;
    width: 100px;
  }
  td:first-child {
    text-decoration: underline;
    cursor: pointer;
  }

  tbody tr:nth-child(odd) {
    background-color: hsla(var(--clr-light));
  }
  tbody tr:hover {
    background-color: #e9e9ef;
  }

  .item_name {
    width: 100%;
    overflow-x: auto;
  }
  .table-date p {
    width: 65px;
    text-align: center;
  }
  .table-date p:nth-child(2) {
    font-weight: 600;
    font-size: 14px;
  }

  .grid {
    display: grid;
    gap: var(--gap, 1rem);
  }

  .flex {
    display: flex;
    gap: var(--gap, 1rem);
  }

  .flex-inline {
    display: inline-flex;
    gap: var(--gap, 1rem);
  }

  .block {
    display: block;
  }

  .no-display {
    display: none;
  }

  .container {
    max-width: 80rem;
    padding-inline: 2em;
    margin-inline: auto;
  }

  .pointer {
    cursor: pointer;
  }

  .underline {
    text-decoration: underline;
  }

  .text-center {
    text-align: center;
  }

  .flow > *:where(:not(:first-child)) {
    margin-top: var(--flow-space, 1rem);
  }

  .btn {
    min-height: 34px;
    margin-bottom: 0;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  .btn-default {
    color: hsla(var(--clr-light));
    background-color: hsla(var(--clr-blue-tint-050));
    border-color: hsla(var(--clr-blue-tint-050));
  }

  .btn-default-ghost {
    color: hsla(var(--clr-blue-tint-100));
    background-color: transparent;
    border-color: hsla(var(--clr-blue-tint-100));
  }

  .btn-secondary {
    background-color: hsla(var(--clr-yellow-shade-050));
    color: hsla(var(--clr-dark));
    border-radius: 5px;
    padding: 0.7em 1.5em;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
  }

  /* colors */
  .bg-light {
    background-color: hsla(var(--clr-light));
  }
  .bg-gray {
    background-color: hsla(var(--clr-gray));
  }
  .bg-blue-dark-700 {
    background-color: hsla(var(--clr-blue-shade-700));
  }
  .bg-blue-tint-050 {
    background-color: hsla(var(--clr-blue-tint-050));
  }
  .bg-skeleton {
    background: linear-gradient(
      90deg,
      #f1efef -24.18%,
      #f9f8f8 50.26%,
      #e7e5e5 114.84%
    );
  }

  .text-black {
    color: hsla(var(--clr-black));
  }
  .text-light {
    color: hsla(var(--clr-light));
  }
  .text-error {
    color: hsla(var(--clr-error));
  }
  .text-blue-dark-050 {
    color: hsla(var(--clr-blue-shade-050));
  }

  .text-black-100 {
    color: hsla(var(--clr-black-shade-100));
  }
  .text-black-300 {
    color: hsla(var(--clr-black-shade-300));
  }
  .text-black-800 {
    color: hsla(var(--clr-black-shade-800));
  }
  .text-black-900 {
    color: hsla(var(--clr-black-shade-900));
  }

  .text-black-tint-900 {
    color: hsla(var(--clr-black-tint-900));
  }

  .text-blue-tint-100 {
    color: hsla(var(--clr-blue-tint-100));
  }
  .text-blue-tint-600 {
    color: hsla(var(--clr-blue-tint-600));
  }

  /* typography */
  .ff-sans {
    font-family: var(--ff-sans);
  }
  .ff-serif {
    font-family: var(--ff-serif-normal);
  }

  .fs-900 {
    font-size: var(--fs-900);
  }
  .fs-800 {
    font-size: var(--fs-800);
  }
  .fs-700 {
    font-size: var(--fs-700);
  }
  .fs-600 {
    font-size: var(--fs-600);
  }
  .fs-500 {
    font-size: var(--fs-500);
  }
  .fs-400 {
    font-size: var(--fs-400);
  }
  .fs-300 {
    font-size: var(--fs-300);
  }
  .fs-200 {
    font-size: var(--fs-200);
  }
  .fs-100 {
    font-size: var(--fs-100);
  }

  .fw-700 {
    font-weight: var(--fw-700);
  }
  .fw-600 {
    font-weight: var(--fw-600);
  }
  .fw-500 {
    font-weight: var(--fw-500);
  }
  .fw-400 {
    font-weight: var(--fw-400);
  }

  .letter-spacing-1 {
    letter-spacing: 4.75px;
  }
  .letter-spacing-2 {
    letter-spacing: 2.7px;
  }
  .letter-spacing-3 {
    letter-spacing: 2.35px;
  }

  /* ----------- */
  /* Components  */
  /* ------------*/

  height: 100%;
  overflow-y: scroll;
  position: relative;

  .outlets {
    width: 31.125em;
    height: 10.125em;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 8px;
    padding: 24px 40px;
  }
  .section-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #262262;
    display: grid;
    place-items: center;
    margin-top: 5px;

    img {
      object-fit: contain;
    }
  }
  .summation {
    display: flex;
    flex-direction: column;

    .total-amount {
      margin-top: auto;
      color: #262262;
      font-size: 23px;
      font-weight: 600;
    }
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  padding: 0 3em;
  font-size: var(--fs-200);
  min-width: 400px;
  overflow-y: hidden;

  thead {
    position: sticky;
    top: 0;
    background-color: #ececec;
    z-index: 9;

    tr {
      text-align: left;
    }
  }

  th,
  td {
    padding: 1.1em 3em;
  }
  td:first-child {
    text-decoration: underline;
    cursor: pointer;
  }

  tbody tr:nth-child(odd) {
    background-color: hsla(var(--clr-light));
  }
  tbody tr:hover {
    background-color: #e9e9ef;
  }

  .table-date p {
    width: 65px;
    text-align: center;
  }
  .table-date p:nth-child(2) {
    font-weight: 600;
    font-size: 14px;
  }
`;

export default GeneratedOrders;
